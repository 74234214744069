import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import logo from "./../assets/logo.png"
import Swal from "sweetalert2";
import toast,{Toaster} from "react-hot-toast"
import axios from "axios"
const Ageentapproval = () => {
    const agent_info=JSON.parse(localStorage.getItem("agent_info"));
    const navigate=useNavigate();
    const handlelogout = () => {
      Swal.fire({
          title: "Are you sure?",
          text: "You will be logged out of your account.",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, log out!",
          cancelButtonText: "Cancel",
      }).then((result) => {
          if (result.isConfirmed) {
              localStorage.removeItem("agent_info");
              localStorage.removeItem("agent_token");         
              navigate("/login");
              toast.success("Logged Out!", "You have been successfully logged out.", "success");
          }
      });
  };
  // --------------------find-agent-data------------------------
  const [agent_details,set_agent_details]=useState([])
  const fetchAgentInfo = async () => {
    try {
        const token = localStorage.getItem("jwtToken"); // Get JWT token from localStorage

        const response = await axios.get(`${process.env.REACT_APP_BASE_URL2}/agent-information/${agent_info._id}`, {
            headers: {
                 Authorization: localStorage.getItem("token"),
                "Content-Type": "application/json",
            },
        });

        if (response.data.success) {
            console.log("Agent Info:", response.data.data);
            set_agent_details(response.data.data)
            return  response.data.data; // Return agent data if needed
        } else {
            console.error("Error:", response.data.message);
        }

    } catch (error) {
        console.error("Axios error:", error.response?.data?.message || "Something went wrong");
    }
};

  useEffect(() => {
    fetchAgentInfo();
    if (agent_info?.status === "deactivated") {
        navigate("/agent/waiting-for-approval", { replace: true });
    } else if (agent_info?.status === "activated") {
        navigate("/agent-dashboard", { replace: true });
    } else {
        navigate("/login", { replace: true });
    }
}, []);

  return (
    <section>
      <Toaster/>
        <header className="w-full h-[10vh] lg:h-[12vh] bg-white shadow-sm border-b-[1px] flex justify-between items-center px-[10px] md:px-[20px] lg:px-[40px] xl:px-[100px] py-[20px] border-[#eee] ">
            <div className="logo">
                <img className='w-[100px] ' src={logo} alt="" />
            </div>
            <div className='flex justify-center items-center gap-[20px]'>
              <h2 className='text-[17px] lg:text-[20px] font-[600]'>{agent_info?.name}</h2>
              <button onClick={handlelogout} className='px-[22px] py-[8px] lg:py-[12px] bg-[#4b7bec] text-[14px] lg:text-[16px] font-[500]  text-white cursor-pointer rounded-[5px]'>Log Out</button>
            </div>
        </header>
        <section className='w-full h-[90vh] lg:h-[88vh] flex justify-center items-center'>
        <section className='w-[90%] md:w-[70%] lg:w-[70%] xl:w-[60%] 2xl:w-[50%] h-auto text-center'>
    <h1 className='text-[22px] lg:text-[30px] font-[600] bg-gradient-to-r from-blue-600 to-indigo-300 inline-block text-transparent bg-clip-text'>
        Hello {agent_info?.name}
    </h1>
    <h2 className='text-[16px] lg:text-[18px] text-[#45aaf2] mb-[5px]'>{agent_info?.email}</h2>
    <p className='text-[16px] text-neutral-500 leading-[25px] mb-[10px]'>
        Thank you for registering with us! Your information has been successfully received and is currently under review by our verification team. 
        We take this step to ensure a safe and trustworthy community for all users. If any discrepancies are found in your details, your account may be rejected. 
        Our team will complete the review process within the next three business days. Once approved, you will receive an email confirmation, and you can log in to your account.
    </p>
    <p className='text-[16px] text-black font-medium'>
        Your approval is in progress. Please stay tuned!
    </p>
</section>

        </section>
    </section>
  )
}

export default Ageentapproval